class customPopup {

    constructor() {
        // document.addEventListener('DOMContentLoaded', () => {
        jQuery(document).on("booked-on-requested-appointment", () => {
            this.el = document.getElementsByTagName('body')[0];
            // console.log(this.el);

            this.config = {
                attributes: true,
                attributeOldValue: true,
                childList: true,
                characterData: true,
                attributeFilter: ['class'],
            };

            this.setObserver();

            this.observer.observe(this.el, this.config);
        });
    }

    setObserver() {
        this.observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                // if (mutation.attributeName === "class") {
                //     // console.log(mutation);
                //     this.removeClassCallback(mutation);
                // }

                mutation.removedNodes.forEach((node) => {
                    let removed = jQuery(node);
                    if (removed.hasClass('booked-modal')) {
                        console.log('The removed node', node);

                        setTimeout(() => {
                            this.createModal();
                        }, 1100);
                    }
                });
            });
        });
    }

    removeClassCallback(mutation) {
        let oldValue = mutation.oldValue.split(' '),
            newValue = mutation.target.className.split(' ');

        let diff = this.getDiff(oldValue, newValue);

        if (diff.indexOf('booked-noScroll') >= 0) {
            setTimeout(() => {
                this.createModal();
            }, 650);
        }
    }

    createModal() {
        this.create_booked_modal();

        let $custom = jQuery('[data-id=thank_you_popup]');
        jQuery('.bm-window').html($custom.html());

        var bookedModal = jQuery('.booked-modal');
        var bmWindow = bookedModal.find('.bm-window');
        bmWindow.addClass('custom-popup')
        bmWindow.css({'visibility': 'hidden'});
        bookedModal.removeClass('bm-loading');
        jQuery(document).trigger("booked-on-new-app");

        this.resize_booked_modal();

        bmWindow.hide();
        jQuery('.booked-modal .bm-overlay').find('.booked-spinner').remove();

        setTimeout(() => {
            bmWindow.css({'visibility': 'visible'});
            bmWindow.show();

            this.observer.disconnect();
        }, 50);
    }

    getDiff(oldValue, newValue) {
        return oldValue.filter((i) => {
            return newValue.indexOf(i) < 0;
        });
    }

    create_booked_modal() {
        var windowHeight = jQuery(window).height();
        var windowWidth = jQuery(window).width();
        if (windowWidth > 720) {
            var maxModalHeight = windowHeight - 295;
        } else {
            var maxModalHeight = windowHeight;
        }

        jQuery('body input, body textarea, body select').blur();
        jQuery('body').addClass('booked-noScroll');
        jQuery('<div class="booked-modal bm-loading"><div class="bm-overlay"></div><div class="bm-window"><div style="height:100px"></div></div></div>').appendTo('body');
        jQuery('.booked-modal .bm-overlay').spin('booked_white');
        jQuery('.booked-modal .bm-window').css({'max-height': maxModalHeight + 'px'});
    }

    resize_booked_modal() {

        var windowHeight = jQuery(window).height();
        var windowWidth = jQuery(window).width();

        var common43 = 43;

        if (jQuery('.booked-modal .bm-window .booked-scrollable').length) {
            var realModalHeight = jQuery('.booked-modal .bm-window .booked-scrollable')[0].scrollHeight;

            if (realModalHeight < 100) {
                realModalHeight = previousRealModalHeight;
            } else {
                previousRealModalHeight = realModalHeight;
            }

        } else {
            var realModalHeight = 0;
        }
        var minimumWindowHeight = realModalHeight + common43 + common43;
        var modalScrollableHeight = realModalHeight - common43;
        var maxModalHeight;
        var maxFormHeight;

        if (windowHeight < minimumWindowHeight) {
            modalScrollableHeight = windowHeight - common43 - common43;
        } else {
            modalScrollableHeight = realModalHeight;
        }

        if (windowWidth > 720) {
            maxModalHeight = modalScrollableHeight - 25;
            maxFormHeight = maxModalHeight - 15;
            var modalNegMargin = (maxModalHeight + 78) / 2;
        } else {
            maxModalHeight = windowHeight - common43;
            maxFormHeight = maxModalHeight - 60;
            var modalNegMargin = (maxModalHeight) / 2;
        }

        jQuery('.booked-modal').css({'margin-top': '-' + modalNegMargin + 'px'});
        jQuery('.booked-modal .bm-window').css({'max-height': maxModalHeight + 'px'});
        jQuery('.booked-modal .bm-window .booked-scrollable').css({'max-height': maxFormHeight + 'px'});
    }

}

new customPopup();