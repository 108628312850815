import './classes/customPopup';

(function($){

    $(function(){

        // $(document).trigger("booked-on-requested-appointment",[redirectObj]);
        $(document).on("booked-on-requested-appointment", () => {
            console.log('booked');
        });

    });

})(jQuery);